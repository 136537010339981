<template>
  <div id="addconsulting"  style="overflow-y: scroll;" :style="{ height: height }">
    <div class="backClass"><a-button type="primary" @click="goback"> 返回 </a-button></div>
    <a-form-model ref="ruleForm" :model="form" :labelCol="{ style: 'width: 130px' }" :wrapper-col="{ span: 8 }"
      :rules="rules">
      <a-form-model-item label="资讯标题" prop="consult_title">
        <a-input style="width: 800px" :disabled="disabled" placeholder="请输入资讯标题，最长不超过100个字" :max-length="100"
          v-model.trim="form.consult_title" />
      </a-form-model-item>
      <a-form-model-item label="封面图片：" prop="lineImage">
        <div class="upload-box" v-if="!form.lineImage" @click="visible2 = true">
          <a-icon style="font-size:14px;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"
            type="plus" />
        </div>
        <a-upload v-else list-type="picture-card" :disabled="disabled" class="avatar-uploader" :show-upload-list="true"
          :file-list="imagefileList" :action="IMG_API + '/oss/files'" @preview="specialPicturePreview"
          :before-upload="beforeSpecialPictureUpload" @change="handleSpPicChange">
          <div v-if="!form.lineImage">
            <a-icon :type="spPicLoading ? 'loading' : 'plus'" />
          </div>
        </a-upload>
        <a-modal :zIndex="100000" :visible="pictureVisible" :footer="null" @cancel="pictureVisible = false">
          <img :src="form.lineImage" alt="avatar" class="imgUpload" style="width: 400px;margin-top: 20px;" />
        </a-modal>
        <span>封面图片比例需为4:3，大小不超过10M格式支持jpg/png/jpeg类型</span>
      </a-form-model-item>
      <a-form-model-item label="分享图：" prop="share_picture">
        <div class="upload-box" v-if="!form.share_picture" @click="visible3 = true">
          <a-icon style="font-size:14px;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"
            type="plus" />
        </div>
        <a-upload v-else list-type="picture-card" :disabled="disabled" class="avatar-uploader" :show-upload-list="true"
          :file-list="imagefileList2" :action="IMG_API + '/oss/files'" @preview="specialPicturePreview2"
          :before-upload="beforeSpecialPictureUpload" @change="handleSpPicChange2">
          <div v-if="!form.share_picture">
            <a-icon :type="spPicLoading ? 'loading' : 'plus'" />
          </div>
        </a-upload>
        <a-modal :zIndex="100000" :visible="pictureVisible2" :footer="null" @cancel="pictureVisible2 = false">
          <img :src="form.lineImage2" alt="avatar" class="imgUpload" style="width: 400px;margin-top: 20px;" />
        </a-modal>
        <span>分享图比例需为5：4，仅用于微信分享展示</span>
      </a-form-model-item>
      <a-form-model-item label="资讯类型：" prop="consult_type_id" :span="24">
        <a-select 
          :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="form.consult_type_id" :disabled="disabled" style="width: 800px" placeholder="请选择资讯类型"
          @change="changeConsult">
          <a-select-option :value="item.id" v-for="item in consultingType" :key="item.id">
            {{ item.value }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="展示项目：" prop="consult_project_list" :span="24">
        <a-select 
          :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple" v-model="form.consult_project_list" :disabled="disabled" style="width: 800px"
          placeholder="请选择展示项目" @change="getModuleList">
          <a-select-option v-for="option in projectList" :key="option.project_id" :value="option.project_id">
            {{ option.project_name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="所属模块" :span="24">
        <a-select 
          :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple" v-model="form.module_project_list" :disabled="disabled" style="width: 800px"
          placeholder="请选择所属模块">
          <a-select-option v-for="option in moduleList" :key="option.moduleId" :value="option.moduleId">
            {{ option.moduleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="配置方式" required>
        <a-radio-group :disabled="disabled" @change="handleChangeConfig" v-model="form.configuration_type">
          <a-radio :value="1">自主编辑</a-radio>
          <a-radio :value="2">第三方链接</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="form.configuration_type === 2" label="链接地址：" prop="external_url">
        <a-textarea :disabled="disabled" v-model="form.external_url"
          placeholder="请输入链接地址，不超过2000字符，需要添加“http://”或“https://”前缀" :auto-size="{ minRows: 3, maxRows: 5 }" />
      </a-form-model-item>
      <a-form-model-item v-if="form.configuration_type === 1" label="资讯内容：" :span="24" required>
        <div class="content">
          <div id="editer"></div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="发布时间：" :span="24" prop="show_time">
        <a-date-picker :disabled="disabled" v-model="form.show_time" :show-time="{ format: 'HH:mm' }"
          format="YYYY-MM-DD HH:mm" placeholder="请选择资讯发布时间"
          :getCalendarContainer="(triggerNode) => triggerNode.parentNode" />
      </a-form-model-item>
      <a-form-model-item  label="展示顺序" :span="24" prop="sort">
        <a-input-number  :disabled="disabled" v-model="form.sort" :min="0" @change="changeNum" :max="999" style="width: 800px;" placeholder="请输入展示顺序" />
      </a-form-model-item>
      <a-form-model-item  label="是否展示阅读量" :span="24" prop="show_display">
        <a-radio-group :disabled="disabled" v-model="form.show_display">
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 6, offset: 8 }" v-if="!disabled">
        <a-button type="primary" @click="submit(1)"> 保存并发布 </a-button>
        <a-button type="primary" @click="submit(2)"
          style="margin-left: 20px; background-color: #fff; color: #000; border: 1px solid #ccc">
          仅保存
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <a-modal dialogClass="dialogClass" :destroyOnClose="true" :zIndex="10004" :confirm-loading="loading"
      :maskClosable="false" title="上传活动封面" :width="800"
      @cancel="visible2 = false; thumbUrl = ''; img = ''; loading = false" @ok="uploadScreenshot" :visible="visible2">
      <a-spin :spinning="spinning">
        <div style="width: 500px;height: 550px;">
          <div style="display: flex;align-items: end;margin-bottom: 20px;cursor:pointer;">
            <div style="width: 104px;position: relative;cursor:pointer">
              <a-button style="width: 100%;position: relative;">
                {{ thumbUrl ? '更换' : '上传' }}图片
                <input
                  style="cursor:pointer;height: 100%;width: 100%;position: absolute;opacity: 0;z-index: 1000;left: 0;top: 0;"
                  type="file" ref="file" @change="fileChange" accept="image/*">
              </a-button>
              <!-- <a-icon @click="uploadjietu" style="font-size:14px;position: absolute;left: 50%;top: 50% ;transform: translate(-50%,-50%);" type="plus" /> -->
            </div>
          </div>
          <cropper style="width: 500px;height: 500px;" class="cropper" ref="cropper" :src="thumbUrl" :stencil-props="{
            aspectRatio: 4 / 3
          }" />
        </div>
      </a-spin>
    </a-modal>
    <a-modal dialogClass="dialogClass" :destroyOnClose="true" :zIndex="10004" :confirm-loading="loading"
      :maskClosable="false" title="上传分享图" :width="800"
      @cancel="visible3 = false; thumbUrl2 = ''; img = ''; loading = false" @ok="uploadScreenshot2" :visible="visible3">
      <a-spin :spinning="spinning">
        <div style="width: 500px;height: 550px;">
          <div style="display: flex;align-items: end;margin-bottom: 20px;cursor:pointer;">
            <div style="width: 104px;position: relative;cursor:pointer">
              <a-button style="width: 100%;position: relative;">
                {{ thumbUrl2 ? '更换' : '上传' }}图片
                <input
                  style="cursor:pointer;height: 100%;width: 100%;position: absolute;opacity: 0;z-index: 1000;left: 0;top: 0;"
                  type="file" ref="file" @change="fileChange2" accept="image/*">
              </a-button>
              <!-- <a-icon @click="uploadjietu" style="font-size:14px;position: absolute;left: 50%;top: 50% ;transform: translate(-50%,-50%);" type="plus" /> -->
            </div>
          </div>
          <cropper style="width: 500px;height: 500px;" class="cropper" ref="cropper" :src="thumbUrl2" :stencil-props="{
            aspectRatio: 5 / 4
          }" />
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import { IMG_API } from "@/config";
import wangEditor from "wangeditor";
import {
  consultingAdd,
  consultingEdit,
  consultingDetail,
  selectTypeListByUserAuth,
  getProjectListByTypeId,
  getModuleList
} from "@/api/consultingManagement";
import { mapState } from "vuex";
import { updataJietu } from "@/api/enterpriseServiceActivitiesManagement";
import moment from "moment";

import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  components: { Cropper },
  watch: {
    'form.consult_type_id': {
      handler(val) {
        if (val) {
          this.getCurrentProjectByTypeId()
        }
      },
      immediate: true,
      deep: true
    },
  },
  data() {
    let checkValue = (rule, value, callback) => {
      if (value.length === 0) {
        return callback(new Error("请上传图片"));
      } else {
        this.$refs.ruleForm.clearValidate("lineImage");
        return callback();
      }
    };
    return {
      thumbUrl: '',
      thumbUrl2: '',
      spinning: false,
      loading: false,
      pictureVisible: false,
      pictureVisible2: false,
      consultingType: [],
      projectList: [],
      form: {
        consult_title: "",
        lineImage: '',
        lineImage2: '',
        consult_type_id: undefined,
        consult_content: "",
        // 配置方式
        configuration_type: 1,
        // 链接地址
        external_url: '',
        // 资讯展示时间
        show_time: '',
        // 展示项目
        consult_project_list: [],
        share_picture: '',// 小程序分享图片
        module_project_list: [],//模块
        sort:'', // 排序
        show_display: 0
      },
      imagefileList: [],
      imagefileList2: [],
      visible2: false,
      visible3: false,
      spPicLoading: false,
      previewVisible: false, // 预览弹窗状态
      previewImage: "", // 预览图片地址
      IMG_API: IMG_API,
      disabled: false,
      id: "",
      rules: {
        consult_title: [{ required: true, message: "请输入资讯标题", trigger: "blur" }],
        lineImage: [{ validator: checkValue, required: true, trigger: "change" }],
        consult_type_id: [
          { required: true, message: '请选择资讯类型', trigger: 'change' }
        ],
        consult_project_list: [
          { required: true, message: '请选择展示项目', trigger: 'change' }
        ],
        external_url: [
          { required: true, message: '请输入链接地址', trigger: 'blur' }
        ],
        show_time: [
          { required: true, message: '请选择资讯展示时间', trigger: 'change' }
        ],
        show_display:[
        { required: true, message: '请选择是否展示阅读量', trigger: 'change' }
        ]
      },
      // 是否禁用上传按钮
      uploadDisable: true,
      project: [], // 项目列表
      projectName: [], // checked选中回显
      moduleList: [],// 所属模块
      height:''
    };
  },
  async created() {
    this.type = this.$route.query.type;
    if (this.type == "info") {
      this.disabled = true;
    }
    await this.getTypeListByUserAuth();

  },
  async mounted() {
    console.log(window.innerHeight);
    this.height = window.innerHeight-160+'px'
    await this.createEditor()
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      await this.consultingInfo();
    }
  },
  methods: {
    // 根据资讯的类型查询相关联的项目
    async getCurrentProjectByTypeId() {
      const res = await getProjectListByTypeId(this.form.consult_type_id)
      const { code, data } = res
      if (code === '200') {
        this.projectList = data
      }
    },
    // 配置方式change事件
    handleChangeConfig(e) {
      const val = e.target.value
      if (val === 1) {
        this.$nextTick(() => {
          this.createEditor()
        })
      }
    },
    // 创建富文本
    async createEditor() {
      const _this = this;
      const editor = new wangEditor(`#editer`);
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.form.consult_content = newHtml;
      };
      editor.config.menus = [
        "fontName",
        "fontSize",
        "foreColor",
        "backColor",
        "underline",
        "italic",
        "bold",
        "justify",
        "splitLine",
        "undo",
        "redo",
        "list",
        "table",
        "image",
        "video",
      ];
      editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
      editor.config.uploadImgServer = this.IMG_API + "/oss/files";
      editor.config.uploadImgShowBase64 = false;
      editor.config.showLinkImg = false;
      editor.config.showLinkVideo = false;
      editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
      editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
      // editor.config.uploadVideoAccept = ['mp4']
      editor.config.uploadFileName = "file";
      editor.config.uploadVideoName = "file";
      editor.config.uploadVideoTimeout = 60000;
      editor.config.uploadImgTimeout = 60000;
      editor.config.uploadVideoHooks = {
        // 上传视频之前
        before: function (xhr, editor, files) {
          console.log(xhr);
          console.log(editor);
          console.log(files);
        },
        // 视频上传并返回了结果，视频插入已成功
        success: function (xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频超时
        timeout: function (xhr) {
          _this.$message.error("上传请求超时");
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function (insertVideoFn, result) {
          let videoHTML =
            '&nbsp;<video poster="' +
            result.redirect_uri +
            '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' +
            result.redirect_uri +
            '" controls style="max-width:100%"></video>&nbsp;';
          editor.cmd.do("insertHTML", videoHTML);
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          // insertVideoFn(result.redirect_uri)
        },
      };
      editor.config.uploadImgHooks = {
        // 上传视频之前
        before: function (xhr) { },
        // 视频上传并返回了结果，视频插入已成功
        success: function (xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频超时
        timeout: function (xhr) {
          _this.$message.error("上传请求超时");
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function (insertVideoFn, result) {
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          insertVideoFn(result.redirect_uri);
        },
      };
      editor.config.customAlert = function (s, t) {
        switch (t) {
          case "success":
            _this.$message.success(s);
            break;
          case "info":
            _this.$message.info(s);
            break;
          case "warning":
            _this.$message.warning(s);
            break;
          case "error":
            _this.$message.error(s);
            break;
          default:
            _this.$message.info(s);
            break;
        }
      };
      editor.config.placeholder = "请输入";
      // 创建编辑器
      editor.create();
      this.editor = editor;
      // 查看的时候禁用富文本
      if (this.$route.query.type === "info") {
        this.editor.disable();
      } else {
        this.editor.enable();
      }
    },
    async specialPicturePreview(file) {
      console.log(file);
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.pictureVisible = true;
    },
    async specialPicturePreview2(file) {
      console.log(file);
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.pictureVisible2 = true;
    },
    beforeSpecialPictureUpload(file) {
      // 校验格式
      console.log(file);
      let that = this
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、png格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt5M = file.size / 1024 / 1024 <= 10;
      if (!isLt5M) {
        this.$message.error("上传图片最大10M");
        return Promise.reject();
      }
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        // img.onload = function () {
        //   const proportion = that.reductionTo(img.width, img.height);
        //   const valid = proportion[0] === 4 && proportion[1] === 3
        //   valid ? resolve() : reject();
        // };
        // img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          that.$message.error("图片不规范，请按要求上传");
          return reject();
        }
      );
      console.log(isJpgOrPng && isLt5M);
      return isJpgOrPng && isLt5M && isSize;
    },
    handleSpPicChange(info) {
      console.log(info);
      this.imagefileList = info.fileList
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "removed") {
        this.form.lineImage = '';
        this.imagefileList = []
        return;
      }
      if (info.file.status === "done") {
        this.loading = false;
        this.form.lineImage = info.file.response.redirect_uri;
        this.imagefileList = [{
          uid: info.file.uid,
          name: info.file.name,
          status: 'done',
          url: info.file.response.redirect_uri
        }]
      }
    },
    handleSpPicChange2(info) {
      console.log(info);
      this.imagefileList2 = info.fileList
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "removed") {
        this.form.share_picture = '';
        this.imagefileList2 = []
        return;
      }
      if (info.file.status === "done") {
        this.loading = false;
        this.form.share_picture = info.file.response.redirect_uri;
        this.imagefileList2 = [{
          uid: info.file.uid,
          name: info.file.name,
          status: 'done',
          url: info.file.response.redirect_uri
        }]
      }
    },
    //计算图片比例
    reductionTo(m, n) {
      let arr = [];
      let a = m;
      let b = n;
      (a >= b) ? (a = m, b = n) : (a = n, b = m);
      if (m != 1 && n != 1) {
        for (let i = b; i >= 2; i--) {
          if (m % i == 0 && n % i == 0) {
            m = m / i;
            n = n / i;
          }
        }
      }
      arr[0] = m;
      arr[1] = n;
      return arr;
    },
    // 获取当前用户拥有项目下的资讯类型
    async getTypeListByUserAuth() {
      const res = await selectTypeListByUserAuth();
      const { code, data } = res
      if (code === '200') {
        this.consultingType = data && data.length && data.map(item => {
          return {
            id: item.consult_type_id,
            value: item.consult_type_name
          }
        })
      }


    },
    // 返回
    goback() {
      // this.$router.go(-1);
      this.$router.push({
        path: "/consultingManagement/content",
      });
    },
    // 详情
    async consultingInfo() {
      let data = {};
      data.id = this.id;
      const res = await consultingDetail(data);
      try {
        if (res.code === "200") {
          this.form = {
            show_display:res.data.show_display,
            id: res.data.id,
            consult_content: res.data.consult_content,
            consult_title: res.data.consult_title,
            consult_type_id: res.data.consult_type_id,
            lineImage: [],
            configuration_type: res.data.configuration_type,
            show_time: res.data.show_time,
            external_url: res.data.external_url,
            share_picture: res.data.share_picture,
            sort:res.data.sort,
            module_project_list:[]
          };
          let iconImg = {
            uid: "1",
            name: "image.png",
            status: "done",
            url: res.data.consult_img,
          };
          let iconImg2 = {
            uid: "1",
            name: "image.png",
            status: "done",
            url: res.data.share_picture,
          };
          this.form.lineImage = [iconImg];
          // 展示的图片
          this.imagefileList = [iconImg]
          this.imagefileList2 = [iconImg2]
          // 预览的图片地址
          this.form.lineImage = res.data.consult_img
          this.form.lineImage2 = res.data.share_picture

          let projectIds = []
          if (res.data.consult_project_list) {
            res.data.consult_project_list.forEach((item) => {
              projectIds.push(item.project_id)
            });
            this.form.consult_project_list = JSON.parse(JSON.stringify(projectIds))
          }
          this.getModuleList()
          res.data.module_project_list.forEach((item) => {
            this.form.module_project_list.push(item.module_id)
          });
          // this.form.module_project_list =[...module_project_list]
          this.editor.txt.html(this.form.consult_content);
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) { }
    },

    // 添加
    submit(value) {
      let obj = {
        ...this.form,
      };
      let arr = this.moduleList.filter(item => {
        return obj.module_project_list.includes(item.moduleId)
      })
      let arr2 = []
      arr.forEach(item => {
        arr2.push({
          module_id: item.moduleId,
          module_name: item.moduleName,
          project_id: item.projectId
        })
      })
      console.log(arr2, '=============>');
      obj.module_project_list = arr2
      if (!this.uploadDisable) {
        this.$message.error("图片上传中, 请等待图片上传成功再进行下一步操作");
        return;
      }
      if (obj.lineImage.length == 0) {
        this.$message.error("请上传图片");
        return;
      }
      obj.consult_state = value;
      obj.consult_img = this.form.lineImage;
      let consult_project_list = [];
      console.log(this.projectList)
      console.log(this.form.consult_project_list)
      this.form.consult_project_list.forEach((item) => {
        this.projectList.forEach((val) => {
          if (item === val.project_id) {
            const data = {
              project_id: val.project_id,
              project_name: val.project_name,
            };
            consult_project_list.push(data);
          }
        });
      });
      obj.consult_project_list = consult_project_list;
      obj.show_time = moment(this.form.show_time).format('YYYY-MM-DD HH:mm:ss')
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.type === "add") {
            const res = await consultingAdd(obj);
            if (res.code == 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            }else{
              this.$message.error(res.msg)
            }
          } else {
            const res = await consultingEdit(obj);
            if (res.code == 200) {
              this.$message.success("修改成功");
              this.$router.go(-1);
            }else{
              this.$message.error(res.msg)
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 预览
    handlePreview(file) {
      this.previewImage = file.url ? file.url : file.response.redirect_uri;
      this.previewVisible = true;
    },

    // 关闭图片图层
    handleCancel() {
      this.previewVisible = false;
    },

    // 弹窗图片
    handleChange(value) {
      this.uploadDisable = false;
      if (value.file.status == "done") {
        this.uploadDisable = true;
      }
      if (value.fileList.length != 0) {
        this.$set(this.form, "lineImage", value.fileList);
      } else {
        this.$set(this.form, "lineImage", []);
      }
    },
    //上传文件之前校验图片大小
    beforeUpload(file) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      const imgSize = file.size / 1024 / 1024 <= 1;
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg或png格式");
        return Promise.reject();
      }
      if (!imgSize) {
        this.$message.error("上传图片大小不能超过1MB！");
        return Promise.reject();
      }
      return isJpgOrPng && imgSize;
    },

    // 上传截图
    uploadScreenshot() {
      this.loading = true
      const { coordinates, image, visibleArea, canvas } = this.$refs.cropper.getResult();
      let newWidth = Math.floor(canvas.width / 4) * 4;
      let newHeight = Math.floor(canvas.width / 4) * 3;
      canvas.toBlob(blob => {
        const file2 = new File([blob], 'fileName', { type: 'jpg', lastModified: Date.now() });
        const img = new Image();
        img.onload = async () => {
          const { width, height } = img;
          const newWidth = Math.floor(width / 4) * 4;
          const newHeight = Math.floor(width / 4) * 3;
          console.log(newWidth, newHeight, '===>');
          const canvas = document.createElement("canvas");
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0, newWidth, newHeight);
          console.log(newWidth, newHeight, '=========');
          const blobString = (await new Promise((resolve, reject) => {
            canvas.toBlob(async (blobFile) => {
              const file2 = new File([blobFile], 'fileName', { type: 'jpg', lastModified: Date.now() });
              const isLt5M = file2.size / 1024 / 1024 <= 10;
              console.log(file2);
              if (!isLt5M) {
                this.$message.error("上传图片最大10M");
                return
              }
              const formData = new FormData();
              formData.append('file', file2);
              updataJietu(this.IMG_API + "/oss/files", formData).then(res => {
                this.loading = false
                this.form.lineImage = res.redirect_uri;
                this.$refs.ruleForm.validateField('lineImage')
                this.visible2 = false
                this.thumbUrl = ''
                this.imagefileList = [{
                  uid: res.id,
                  name: res.name,
                  status: 'done',
                  url: res.redirect_uri
                }]
                console.log(res, '===========>');
              })
              resolve(blobFile);
            }, 'image/jpeg');
          }).catch((err) => {
            throw new Error(err);
          }))
          // 释放canvas ，避免内存泄露
          this.releaseCanvas(canvas);
        }
        img.src = URL.createObjectURL(file2);
      }, 'image/jpeg')
    },
    // 选择文件
    fileChange(e) {
      let file = e.target.files[0];
      this.spinning = true
      const isLt5M = file.size / 1024 / 1024 <= 10;
      if (!isLt5M) {
        this.$message.error({ content: "上传图片最大10M" });
        this.spinning = false
        return
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.thumbUrl = reader.result
        this.spinning = false
      }
      reader.onerror = function (err) {
        console.log(err);
        this.spinning = false
      }
    },
    // 选择文件
    fileChange2(e) {
      let file = e.target.files[0];
      this.spinning = true
      const isLt5M = file.size / 1024 / 1024 <= 10;
      if (!isLt5M) {
        this.$message.error({ content: "上传图片最大10M" });
        this.spinning = false
        return
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.thumbUrl2 = reader.result
        this.spinning = false
      }
      reader.onerror = function (err) {
        console.log(err);
        this.spinning = false
      }
    },
    // 上传截图
    uploadScreenshot2() {
      this.loading = true
      const { coordinates, image, visibleArea, canvas } = this.$refs.cropper.getResult();
      let newWidth = Math.floor(canvas.width / 5) * 5;
      let newHeight = Math.floor(canvas.width / 5) * 4;
      canvas.toBlob(blob => {
        const file2 = new File([blob], 'fileName', { type: 'jpg', lastModified: Date.now() });
        const img = new Image();
        img.onload = async () => {
          const { width, height } = img;
          const newWidth = Math.floor(width / 5) * 5;
          const newHeight = Math.floor(width / 5) * 4;
          console.log(newWidth, newHeight, '===>');
          const canvas = document.createElement("canvas");
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0, newWidth, newHeight);
          console.log(newWidth, newHeight, '=========');
          const blobString = (await new Promise((resolve, reject) => {
            canvas.toBlob(async (blobFile) => {
              const file2 = new File([blobFile], 'fileName', { type: 'jpg', lastModified: Date.now() });
              const isLt5M = file2.size / 1024 / 1024 <= 10;
              console.log(file2);
              if (!isLt5M) {
                this.$message.error("上传图片最大10M");
                return
              }
              const formData = new FormData();
              formData.append('file', file2);
              updataJietu(this.IMG_API + "/oss/files", formData).then(res => {
                this.loading = false
                this.form.lineImage2 = res.redirect_uri
                this.form.share_picture = res.redirect_uri;
                this.visible3 = false
                this.thumbUrl2 = ''
                this.imagefileList2 = [{
                  uid: res.id,
                  name: res.name,
                  status: 'done',
                  url: res.redirect_uri
                }]
                console.log(res, '===========>');
              })
              resolve(blobFile);
            }, 'image/jpeg');
          }).catch((err) => {
            throw new Error(err);
          }))
          // 释放canvas ，避免内存泄露
          this.releaseCanvas(canvas);
        }
        img.src = URL.createObjectURL(file2);
      }, 'image/jpeg')
    },
    changeConsult() {
      console.log('==========');
      this.form.consult_project_list = []
    },
    async getModuleList() {
      this.form.module_project_list = []
      let res = await getModuleList({ moduleType: 8, projectIdList: this.form.consult_project_list })
      console.log(res, '============>');
      this.moduleList = res.data || []
    },
    changeNum(){
      if(String(this.form.sort).indexOf('.')>-1 ){
        this.form.sort = ''
      }else{

      }
    }
  },
};
</script>
<style scoped lang="scss">
.backClass {
  display: flex;
  justify-content: flex-end;
}

.ant-form-item {
  display: flex;
}

.upload_tip {
  line-height: 20px;
  font-size: 12px;
}

.content {
  width: 800px;
}

.upload-box {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
  position: relative;
}

::v-deep .w-e-toolbar {
  z-index: 3 !important;
}

::v-deep .w-e-text-container {
  z-index: 1 !important;
}
</style>